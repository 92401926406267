<template>
  <anchor_list></anchor_list>
</template>

<script>
import anchor_list from "./list"
export default {
  name: "sect_index",
  components: {
    anchor_list,
  }
}
</script>

<style scoped>

</style>
